import React from 'react';
import { SectionFeatured, SectionFeaturedBottom, SectionFeaturedContent } from '@/components/section';
import GroupCardFlights from '@/features/home/components/GroupCardFlights';
import { Text } from '@/components/typography';

export interface SectionFlightProps {
  title?: string;
  description?: string;
  offers: [FeaturedFlight];
}

const FeaturedFlight = ({ title, description, offers, ...props }: SectionFlightProps) => {
  return (
    offers.length && (
      <SectionFeatured bgColor="primary_100" title={title || ''}>
        <SectionFeaturedContent>
          <GroupCardFlights data={offers} />
        </SectionFeaturedContent>
        <SectionFeaturedBottom>
          <Text size="sm">{description || ''}</Text>
        </SectionFeaturedBottom>
      </SectionFeatured>
    )
  );
};

export default FeaturedFlight;
